let showMyPages, sendMessage, sendFile, regLongLiveToken, sendComment, likeComment, hideComment, sendPrivateMessage, getCommentInfo, sendPrivateFile, unlikeComment;
const permissions = [
  "pages_show_list",
  "read_page_mailboxes",
  "pages_messaging",
  "pages_messaging_subscriptions",
  "pages_read_engagement",
  "pages_manage_metadata",
  "pages_read_user_content",
  "public_profile",
  "pages_manage_engagement",
  "business_management",
];
const subscribed_fields = ["messages", "feed", "message_echoes", "messaging_postbacks", "messaging_referrals"];
const appId = process.env.VUE_APP_FB_APP_ID;

(function () {
  // Example
  // window.addEventListener("load",function(){
  //     document.getElementById("buttonLogin").addEventListener("click",function(e){

  //         FB.getLoginStatus(function (response) {
  //             if (response.status === 'connected') {
  //                 showMyPages();
  //             }
  //             else {
  //                 window.FB.login(function(){
  //                     showMyPages();
  //                 },{scope:permissions});
  //             }
  //         });
  //     })
  // });

  // In component must be use indow.FB.api('/me/accounts') nested in window.FB.login
  // window.FB.login(function(){
  //     fb.showMyPages();
  // },{ scope: fb.permissions });

  showMyPages = function () {
    window.FB.api(
      "/me/accounts?limit=100",
      (resp) => {
        for (let page of resp.data) {
          connectTo(page);
        }
      },
      { scope: permissions }
    );
  };

  const connectTo = function (page) {
    window.FB.api(
      "/" + page.id + "/subscribed_apps",
      "post",
      {
        scope: permissions,
        access_token: page.access_token,
        subscribed_fields,
      },
      (r) => {
        if (r.success) {
          console.log("subscribed", r);
        } else {
          console.log("error in subscription", r.error);
        }
      }
    );
  };

  sendMessage = function (params) {
    return new Promise((resolve, reject) => {
      const body = {
        recipient: {
          id: params.recipientId,
        },
        message: {
          text: params.content,
        },
      };
      if(params.over24 == true) {
        body.messaging_type = "MESSAGE_TAG";
        body.tag = "HUMAN_AGENT";
      }
      window.FB.api(
        "/me/messages?access_token=" + params.accessToken,
        "post",
        body,
        (r) => {
          if (r.message_id) {
            resolve("success", r);
          } else {
            reject(r.error);
          }
        }
      );
    });
  };

  sendComment = function (params) {
    return new Promise((resolve, reject) => {
      let body = {
        message: params.content,
      };
      if(params.attachment_url) {
        body.attachment_url = params.attachment_url;
      }
      
      window.FB.api(
        "/" + params.recipientId + "/comments?access_token=" + params.accessToken,
        "post",
        body,
        (r) => {
          if (r.id) {
            resolve("success", r);
          } else {
            reject(r.error);
          }
        }
      );
    });
  };

  sendFile = function (params) {
    return new Promise((resolve, reject) => {
      const body = {
        recipient: {
          id: params.recipientId,
        },
        message: {
          attachment: {
            type: params.type,
            payload: {
              is_reusable: true,
              url: params.fileUrl,
            },
          },
        },
      };
      window.FB.api(
        "/me/message_attachments?access_token=" + params.accessToken,
        "post",
        body,
        (r) => {
          if (r.attachment_id) {
            // resolve("success", r);

            // return new Promise((resolve, reject) => {
              const msgParams = {
                recipient: {
                  id: params.recipientId,
                },
                message: {
                  attachment: {
                    type: "image",
                    payload: {
                      attachment_id: r.attachment_id,
                    },
                  },
                },
              };
              window.FB.api(
                "/me/messages?access_token=" + params.accessToken,
                "post",
                msgParams,
                (r) => {
                  if (r.message_id) {
                    resolve("success", r);
                  } else {
                    reject(r.error);
                  }
                }
              );
            // });
          } else {
            reject(r.error);
          }
        }
      );
    });
  };

  regLongLiveToken = function (params) {
    var clientSecret = params.secret;
    window.FB.api(
      "/oauth/access_token?grant_type=fb_exchange_token&client_id=" +
        appId +
        "&client_secret=" +
        clientSecret +
        "&fb_exchange_token=" +
        params.accessToken,
      "get",
      (r) => {
        console.log(r);
      }
    );
  };

  likeComment = function (params) {
    return new Promise((resolve, reject) => {
      const body = {};
      window.FB.api(
        "/"+ params.comment_id + "/likes?access_token=" + params.accessToken,
        "post",
        body,
        (r) => {
          if(r.success) {
            resolve("success", r);
          } else {
            reject("error in subscription", r.error);
          }
        }
      );
    });
  };

  hideComment = function (params) {
    return new Promise((resolve, reject) => {
      const body = {
        is_hidden: true,
        access_token: params.accessToken
      };
      window.FB.api(
        "/"+ params.comment_id,
        "post",
        body,
        (r) => {
          if(r.success) {
            resolve("success", r);
          } else {
            reject("error in subscription", r.error);
          }
        }
      );
    });
  };

  getCommentInfo = function (params) {
    return new Promise((resolve, reject) => {
      window.FB.api(
        "/" + params.page_id + '/feed?fields=can_reply_privately,from,message,comments{can_reply_privately,from,message}&access_token=' + params.access_token,
        "get",
        (r) => {
          if(r.success) {
            resolve("success", r);
          } else {
            reject("error in getCommentInfo", r.error);
          }
        }
      );
    });
  };

  sendPrivateMessage = function (params) {
    return new Promise((resolve, reject) => {
      const body = {
        recipient: {
          comment_id: params.comment_id, // or post_id
        },
        message: { text: params.message },
        message_type: 'RESPONSE',
        access_token: params.access_token,
      };

      window.FB.api(
        "/" + params.page_id + '/messages',
        "post",
        body,
        (r) => {
          if(r.message_id) {
            resolve("success", r);
          } else {
            reject(r.error);
          }
        }
      );
    });
  };

  sendPrivateFile = function (params) {
    return new Promise((resolve, reject) => {
      const body = {
        recipient: {
          comment_id: params.comment_id, // or post_id
        },
        message_type: 'RESPONSE',
        message: {
          attachment: {
            type: params.type,
            payload: {
              is_reusable: true,
              url: params.fileUrl,
            },
          },
        },
      };
      window.FB.api(
        "/me/message_attachments?access_token=" + params.accessToken,
        "post",
        body,
        (r) => {
          if (r.attachment_id) {
            // return new Promise((resolve, reject) => {
              const msgParams = {
                recipient: {
                  comment_id: params.comment_id, // or post_id
                },
                message_type: 'RESPONSE',
                message: {
                  attachment: {
                    type: "image",
                    payload: {
                      attachment_id: r.attachment_id,
                    },
                  },
                },
              };
              window.FB.api(
                "/me/messages?access_token=" + params.accessToken,
                "post",
                msgParams,
                (r) => {
                  if (r.message_id) {
                    resolve("success", r);
                  } else {
                    reject("error in subscription", r.error);
                  }
                }
              );
            // });
          } else {
            reject("error in subscription", r.error);
          }
        }
      );
    });
  };

  unlikeComment = function (params) {
    return new Promise((resolve, reject) => {
      const body = {
        access_token: params.accessToken
      };
      window.FB.api(
        "/"+ params.comment_id + "/likes",
        "delete",
        body,
        (r) => {
          if(r.success) {
            resolve("success", r);
          } else {
            reject("error in subscription", r.error);
          }
        }
      );
    });
  };

})();

window.fbAsyncInit = function () {
  window.FB.init({
    appId: appId,
    autoLogAppEvents: true,
    xfbml: true,
    version: "v19.0",
  });
};

const fb = {
  showMyPages,
  sendMessage,
  sendFile,
  regLongLiveToken,
  permissions,
  subscribed_fields,
  appId,
  sendComment,
  likeComment,
  hideComment,
  sendPrivateMessage,
  getCommentInfo,
  sendPrivateFile,
  unlikeComment,
};

export default fb;
