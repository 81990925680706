<template>
  <v-row>
    <v-col class="main-page v-responsive d-flex align-center">
      <v-container
        id="user-profile"
        fluid
        tag="section"
      >
          <v-list-item-title class="headline mb-2 text-center">
            Không có quyền truy cập
              <br/>
              <v-btn
                      color=""
                      class="success--text mt-2"
                      @click="returnToHome()"
              >
                  Trang chủ
              </v-btn>
          </v-list-item-title>

      </v-container>
    </v-col>
  </v-row>
</template>

<script>
// import TokenService from '@/helpers/token'
export default {
  name: 'error-403',
  methods: {
    returnToHome() {
      // let permissions = TokenService.getPermissions()
      // if (permissions.includes('dashboard_read')) {
      //   this.$router.push('/')
      // } else {
      //   this.$router.push('/contact/contact-list')
      // }
    }
  }
}
</script>
<style lang="scss" scoped>
.main-page {
    background-image: url("~@/assets/clint-mckoy.jpg");
    background-repeat: no-repeat;
    background-size: auto;
}
</style>
