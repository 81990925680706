import Vue from 'vue'
Vue.mixin({
    methods: {
      checkValidatePhone(val) {
        if (val) {
            const phoneSplit = val.split(" ");
            const validatePhone = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
            const phone = phoneSplit.filter((i) => {
              return i.match(validatePhone);
            });
            if (phone.length > 0) {
              return {
                ...val,
                btnPhone: phone,
              };
            } else {
              return { ...val };
            }
          } else {
            return { ...val };
          }
      }
    },
  });