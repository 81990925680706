import axios from 'axios'
import _ from 'lodash'
import TokenService from '../helpers/token'
import store from '../store'

const resource = `${process.env.VUE_APP_API}`;

const genParams = (params) => {
    let paramStr = '?';

    for (const property in params) {
        paramStr += `${property}=${params[property]}&`;
    }

    return paramStr;
};


const STATUS_CODE_FORBIDDEN = 403
// const STATUS_SUCCESS = 200
const STATUS_INTERNAL_SERVER_ERROR = 500

const baseUrl = process.env.VUE_APP_BASE_URL
const apiUrl = process.env.VUE_APP_API
const options = {
  baseURL: baseUrl,
  apiURL:apiUrl
}


const STATUS_INTERNAL_SERVER_MAINTENANCE = 452
const urlMaintenance = 'https://baotri.vuihoc.vn/'

class ApiService {
    axiosInstance;

    constructor() {
        this.initAxiosInstance()
    }

    initAxiosInstance(token = null) {
        if (store.state.token !== null) {
            options.headers = {
                Authorization: token ? `Bearer ${token}` : `Bearer ${store.state.token}`,
                Language: _.get(TokenService.getLang(), '', 'en'),
            }
        }
        this.axiosInstance = axios.create(options)
        this.axiosInstance.interceptors.response.use(
            async (response) => {
              const statusCode = _.get(response, 'data.code', STATUS_INTERNAL_SERVER_ERROR)

              if (statusCode === STATUS_CODE_FORBIDDEN) {
                window.location.href = '/error-403'
              }

              return {
                success: true,
                data: _.get(response, 'data.data', []),
                message: _.get(response, 'data.message', null),
                unread_count: _.get(response, 'data.unread_count', null),
              }
            },

            async (error) => {
              if(error.response && error.response.status == STATUS_INTERNAL_SERVER_MAINTENANCE) {
                  window.location = urlMaintenance
              }
              if (error.response) {
                return {
                  error: true,
                  status: STATUS_INTERNAL_SERVER_ERROR,
                  message: _.get(error, 'message', ''),
                  errors: _.get(error, 'response.data', {}),
                  data:_.get(error,'data',[])
                }
              }
              if (error.request) {
                return {
                  error: true,
                  status: STATUS_INTERNAL_SERVER_ERROR,
                  message: _.get(error, 'message', ''),
                  errors: _.get(error, 'request', {}),
                }
              }
              return {
                error: true,
                status: STATUS_INTERNAL_SERVER_ERROR,
                message: _.get(error, 'message', ''),
              }
            }
            ,
        )
    }

    get(apiUrl, params) {
        return this.axiosInstance.get(`${resource}${apiUrl}${genParams(params)}`)
    }

    getArr(apiUrl, params) {
      return this.axiosInstance.get(`${resource}${apiUrl}`, {params})
    }

    post(apiUrl, body) {
        return this.axiosInstance.post(`${resource}${apiUrl}`, body)
    }

    put(apiUrl, body) {
        return this.axiosInstance.put(`${resource}${apiUrl}`, body)
    }

    delete(apiUrl, body) {
        return this.axiosInstance.delete(`${resource}${apiUrl}`, {data: body})
    }
}

export default ApiService;
