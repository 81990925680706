import Repository from './Repository'
import ApiService from './ApiService'

class AuthService {
    resource = process.env.VUE_APP_API_ACCOUNT;

    async login(payload) {
        return await Repository.post(`${this.resource}/auth/login`, payload)
    }

    async getProfile(payload) {
        const api = new ApiService
        api.initAxiosInstance()
        return await api.axiosInstance.get(`${this.resource}/auth/me`, payload)
    }

    async getMaketingList(payload) {
        const api = new ApiService
        api.initAxiosInstance(process.env.VUE_APP_EXTERNAL_KEY)
        return await api.axiosInstance.get(`${this.resource}/api/user/identification?identification=2`, payload)
    }
}

const authService = new AuthService;
export { authService };
export default AuthService;
