import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'

import store from '../store'
import TokenService from '../helpers/token'
import { authService } from '../repositories/AuthService'
import { socketService } from '../plugins/socket'

import Error403 from '../views/Error403.vue'
import {constant} from "@/helpers/constant";

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Home',
    component: () => import(/* webpackChunkName: "chat" */ '../views/Chat.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/Chat.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "customer" */ '../views/Customer.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/labels',
    name: 'labels',
    component: () => import(/* webpackChunkName: "setting" */ '../views/Labels.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: `/${constant.WORKSCHEDULEMANAGEMENT}`,
    name: constant.WORKSCHEDULEMANAGEMENT,
    component: () => import(/* webpackChunkName: "setting" */ '../views/workScheduleManagement/index.vue'),
    meta: {
      requiresAuth: true,
      roles: [
          'bitu-schedule-management_read'
      ]
    },
  },
  {
    path: `/${constant.REPORT}`,
    name: constant.REPORT,
    component: () => import(/* webpackChunkName: "setting" */ '../views/Report.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/otherSetting',
    name: 'otherSetting',
    component: () => import(/* webpackChunkName: "setting" */ '../views/OtherSetting.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/schedule-management',
    name: 'ScheduleManagement',
    component: () => import(/* webpackChunkName: "setting" */ '../views/Schedule.vue'),
    meta: {
      requiresAuth: true,
      roles: [
          'bitu-shift-management_read'
      ]
    },
  },
  {
    path: '/403',
    name: 'Error403',
    component: Error403
  },
  {
    path: "*",
    component: Error403
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // const isPublic = _.get(to.meta, 'public', false)
  // const onlyWhenLoggedOut = _.get(to.meta, 'onlyWhenLoggedOut', false)
  const loggedIn = !! store.state.token
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (loggedIn) {
      authService.getProfile().then((res) => {
        TokenService.updateProfileInto(res.data)
        store.dispatch(
            "SET_USER",
            _.get(res, "data", null)
        );
        const permissions = _.get(res, 'data.role.permissions', []).map((p) => `${p.model}_${p.action}`);
        TokenService.setPermissions(permissions);
        store.dispatch('SET_PERMISSION', permissions);
        if (to.meta.roles) {
          if (!_.intersection(to.meta.roles, store.state.permissions).length > 0) {
            return next({name: 'Error403'})
          }
        }
        next()
      }).catch((err) => {
        console.log(err, 'Cant get user logged');
        // next({ name: 'Login' })
      })
      if(!Vue.prototype.$socket) {
        socketService.initSocket()
      }
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }

})

export default router
