import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './plugins/fb-app'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import './mixins/index'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/vi.js';


Vue.prototype.moment = moment

import './assets/app.scss';

Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(DatePicker);
Vue.use(Loading)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
