<template>
  <v-app>
    <!-- <Menu /> -->
    <!-- <v-main> -->
    <router-view />
    <!-- </v-main> -->
  </v-app>
</template>

<script>
// import Menu from './components/Menu.vue';

export default {
  name: "App",

  data: () => ({
    totalUnRead: null,
  }),
  components: {
    // Menu,
  },
  sockets: {
    new_conversation: function () {
      // this.getConversationCreated();
    },
    update_conversation: function (data) {
      this.updateConversations(data);
    },
  },
  methods: {
    updateConversations(itemUser) {
      if (itemUser.unread_count == 1) {
        this.$store.dispatch(
          "SET_TOTALUNREAD",
          this.$store.state.totalUnRead + 1
        );
      }
    },
  },
};
</script>
